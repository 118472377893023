export const routes = [
    {
        path: '/groups',
        name: 'groups.browse',
        component: () => import(/* webpackChunkName: "GroupsBrowse" */ '@/views/app/GroupsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/groups/create',
        name: 'groups.create',
        component: () => import(/* webpackChunkName: "GroupsActions" */ '@/views/app/GroupsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/groups/:id/edit',
        name: 'groups.edit',
        component: () => import(/* webpackChunkName: "GroupsActions" */ '@/views/app/GroupsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/groups/:id/delete',
        name: 'groups.delete',
        component: () => import(/* webpackChunkName: "GroupsActions" */ '@/views/app/GroupsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]